/** {
    margin: 0;
    padding: 0;
}

html {
    font-size: 18px;
}

.App {
    padding: 50px;
}

.details__wrapper {
    max-width: 400px;
    margin: 10em auto;
}

.form__item {
    margin-bottom: 1.6em;
}

label,
input {
    display: block;
    width: 100%;
}

.d-flex {
    display: flex !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.input__error input {
    border-color: red;
}

.error__feedback {
    color: red;
}*/

.dynamic-dropdown {
    min-height: auto !important;
    max-height: 100px !important;
    overflow-y: auto !important;
}

.PhoneInputInput {
    min-height: 45px;
    border: 1px solid black;
    border-radius: 10px;
}

.address-dropdown .ant-select-selector {
    padding: 0.8rem !important;
    height: min-content !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    /* Use !important if necessary */
}

.address-dropdown .ant-select-selection-search {
    font-size: 1.125rem !important;
    /* Use !important if necessary */
}
.ant-space-item {
    width: 100%;
}